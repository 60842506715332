import {EventsStore} from "../types";
import {action, computed, memo, thunk} from "easy-peasy";
import moment from "moment";
import axios from "axios";

export const SECTION_VIEW = "SECTION_VIEW";
export const ZONE_VIEW = "ZONE_VIEW";

export const EventStore: EventsStore = {
  rows: [],
  notes: [],
  skyboxData: null,
  loading: false,
  mapSVG: "",
  mapUrl: "",
  sectionMapping: {},
  selectedEvent: null,
  currentEventLoading: null,
  mapSections: {},
  mapZones: {},
  mapSectionsArray: computed([(state) => state.mapSections], (mapSections) => {
    if (!mapSections) {
      return [];
    }
    return Object.keys(mapSections).map((key: any) => {
      return mapSections[key];
    });
  }),
  mapSectionsName: computed([(state) => state.mapSections], (mapSections) => {
    if (!mapSections) {
      return [];
    }
    return Object.keys(mapSections).map((key: any) => {
      return mapSections[key]?.name?.toLowerCase();
    });
  }),
  mapView: SECTION_VIEW,
  notesSorted: computed((state) => {
    return state.notes.sort((a: any, b: any) => {
      if (a.pinned < b.pinned) {
        return 1;
      }
      if (a.pinned > b.pinned) {
        return -1;
      }

      return moment(a.created).isBefore(moment(b.created)) ? 1 : -1;
    });
  }),
  getSectionFromZone: computed((state) =>
    memo((zoneId: any) => state.mapZones[zoneId], 999999)
  ),
  getSection: computed((state) =>
    memo((sectionId: any) => state.mapSections.find((s:any) => s.id==sectionId), 999999)
  ),
  selectZone: action((state, payload) => {
    const allSectionSelected = state.mapSectionsArray
      .filter((section: any) => section.zi === payload.zoneId)
      .every((section: any) => payload.sections.includes(section.sectionId));

    Object.keys(state.mapSections).forEach((section: any) => {
      const isHighlighted = state.mapSections[section].zi === payload.zoneId;
      state.mapSections[section].isHighlighted = isHighlighted;
      if (isHighlighted) {
        state.mapSections[section].allSectionInZoneSelected =
          allSectionSelected;
      }
    });
  }),
  unselectZone: action((state) => {
    Object.keys(state.mapSections).forEach((section: any) => {
      state.mapSections[section].isHighlighted = false;
      state.mapSections[section].allSectionInZoneSelected = false;
    });
  }),
  setMapView: action((state, payload) => {
    state.mapView = payload;
  }),
  eventId: computed((state) => {
    if (state.selectedEvent) {
      return state.selectedEvent.id;
    }
    if (state.currentEventLoading) {
      return state.currentEventLoading.id;
    }
    return null;
  }),
  isGA: computed((state) => {
    return !state.mapSections;
  }),
  seatgeekId: computed((state) => {
    if (state.selectedEvent) {
      return state.selectedEvent.seatgeek_id;
    }
    if (state.currentEventLoading) {
      return state.currentEventLoading.seatgeek_id;
    }
    return null;
  }),
  seatgeekUrl: computed((state) => {
    if (state.selectedEvent) {
      return state.selectedEvent.seatgeek_url;
    }
    if (state.currentEventLoading) {
      return state.currentEventLoading.seatgeek_url;
    }
    return null;
  }),
  ticketmasterId: computed((state) => {
    if (state.selectedEvent) {
      return state.selectedEvent.ticketmaster_id;
    }
    if (state.currentEventLoading) {
      return state.currentEventLoading.ticketmaster_id;
    }
    return null;
  }),
  stubhubId: computed((state) => {
    if (state.selectedEvent) {
      return state.selectedEvent.stubhub_id;
    }
    if (state.currentEventLoading) {
      return state.currentEventLoading.stubhub_id;
    }
    return null;
  }),
  addNoteThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/events/notes`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" },
    });
    const jsonData = await response.json();
    actions.addNote(jsonData);
  }),
  addNote: action((state, payload) => {
    state.notes = payload;
  }),
  clearMapFilters: action((state, payload) => {
    state.selectedSections = [];
  }),
  toggleMapFilter: action((state, payload) => {
    if (state.selectedSections.includes(payload)) {
      state.selectedSections = state.selectedSections.filter(
        (v: string) => v !== payload
      );
    } else {
      state.selectedSections = [...state.selectedSections, payload];
    }
  }),
  selectedSections: [],
  unselectEvent: action((state, payload) => {
    state.selectedEvent = null;
    state.selectedSections = [];
    state.notes = [];
    state.selectedSections = [];
    state.skyboxData = null;
  }),
  loadEventsThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/events`);
    const events: any = await response.json();
    actions.loadEvents(events);
  }),
  loadEvents: action((state, payload) => {
    state.rows = payload;
    state.loading = false;
  }),
  setToLoading: action((state) => {
    state.loading = true;
  }),
  setCurrentEventLoading: action((state, payload) => {
    state.currentEventLoading = payload;
  }),
  loadNotes: action((state, payload) => {
    state.notes = payload;
  }),
  updateNoteThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/events/notes/${payload.id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData: any = await response.json();
    actions.loadNotes(jsonData);
  }),
    saveMappingSectionThunk: thunk(async (actions, payload) => {
      await fetch(
          `/api/events/mapping/${payload.eventId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.dic)
          }
      );

      actions.saveMappingSection(payload);
    }),
  saveMappingSection: action((state, payload) => {
    state.sectionMapping = payload.dic;
  }),
    togglePinThunk: thunk(async (actions, payload) => {
    actions.togglePin(payload);
    await fetch(
      `/api/events/notes/${payload.pinned === 1 ? "pin" : "unpin"}/${
        payload.id
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }),
  togglePin: action((state, payload) => {
    state.notes = state.notes.map((note: any) => {
      if (note.id === payload.id) {
        return {
          ...note,
          pinned: payload.pinned,
        };
      }
      return note;
    });
  }),
  deleteNoteThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/events/notes/${payload}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData: any = await response.json();
    actions.loadNotes(jsonData);
  }),
  matchEventThunk: thunk(async (actions, payload) => {
    await fetch(`/api/events/${payload.id}`, {
      method: "PUT",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ seatgeek_id: payload.seatgeek_id }),
    });
    actions.loadEventDataThunk(payload);
  }),
  unmatchEventThunk: thunk(async (actions, payload) => {
    await fetch(`/api/events/${payload.id}`, {
        method: "DELETE",
        headers: { 'Content-Type': 'application/json' },
    });
    actions.loadEventDataThunk(payload);
  }),
  loadNotesThunk: thunk(async (actions, payload) => {
    const response = await fetch(`/api/events/notes/${payload.id}`);
    const jsonData: any = await response.json();
    actions.loadNotes(jsonData);
  }),
  loadEventDataThunk: thunk(async (actions, payload) => {
    try {
      actions.unselectEvent();
      actions.setCurrentEventLoading(payload);
      actions.setToLoading();
      actions.loadNotesThunk(payload);
      actions.loadMeteoThunk(payload);
      actions.loadGetInsThunk(payload);
      const response = await fetch(`/api/events/${payload.id}`);
      const jsonData: any = await response.json();

      actions.loadOnsaleDateThunk(jsonData.event.ticketmaster_id);
      actions.loadEventData(jsonData);
      actions.setCurrentEventLoading(null);
    } catch (err) {
      console.log(err);
    }
  }),
  loadEventData: action((state, payload) => {
    state.selectedEvent = payload.event;
    const mapSections: any = payload.mapData ? payload.mapData.pages[0].segments : null
    const mapZones: any = {};

    /*if (payload.event.venue_configuration) {
      payload.event.venue_configuration.svgMap.forEach(
        (section:any) => {
          if (section.id.substr(0,3) === "row") {
            return;
          }
          mapSections[section.id] = section;
        }
      );
    }
*/

    state.sectionMapping = getMappingDic(payload.event?.section_mapping);

    state.mapSVG = payload.event?.map_url_sh;
    state.mapUrl = payload.mapData ? payload.mapData.pages[0].images[0].url : null
    state.mapZones = mapZones;
    state.mapSections = mapSections;
    state.skyboxData = payload.inventoryUpdate;
    state.selectedSections = [];
    state.loading = false;
  }),

  meteo: null,
  loadMeteoThunk: thunk(async (actions, payload) => {
    axios.get(`https://kobe.plessinc.com/api/events/weather/${payload.id}`)
        .then(function (response) {
              console.log(response.data.weather[0])
          actions.loadMeteo(response.data.weather[0]);
            }
        );

    actions.loadMeteo(payload);
  }),
  loadMeteo: action((state, payload) => {
    state.meteo = payload;
  }),

  onsaleDate: null,
  loadOnsaleDateThunk: thunk(async (actions, payload) => {
    const tmId = payload;
    if(!tmId) return;
    await axios.get(`/api/events/onsaledate/${tmId}`)
        .then(function (response) {
          const date = moment(response.data.sale_start).format('MM/DD/YYYY');
          actions.loadOnsaleDate(date);
        });
  }),
  loadOnsaleDate: action((state, payload) => {
    state.onsaleDate = payload;
  }),

  getIns: null,

  loadGetInsThunk: thunk(async (actions, payload) => {
    const seatgeekId = payload.seatgeek_id;
    if(!seatgeekId) return;
    await axios.get(`/api/events/getins/${seatgeekId}`)
        .then(function (response) {
                actions.loadGetIns(response.data);
            }
        );
  }),
  loadGetIns: action((state, payload) => {
    state.getIns = payload;
  }),

  lowestPrice: null,
};

const getMappingDic = (data: string) => {
  try {
    if (!data) {
      return {};
    }
    return JSON.parse(data);
  } catch (err) {
    console.log(err);
    return {};
  }
}