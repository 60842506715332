import React from 'react';
import { ReactComponent as SHLogo } from "../../../assets/images/stubhub-icon.svg";
import { ReactComponent as SGLogo } from "../../../assets/images/seatgeek-icon.svg";
import { ReactComponent as TMLogo } from "../../../assets/images/ticketmaster-icon.svg";

const MarketplacesIcons = ({ tmId, sgId, shId, vividId }) => {

  return (
    <div style={{ marginRight: 150, display: "flex" }}>
        {vividId && (
            <span style={{cursor: "pointer", margin: 'auto', whiteSpace: "nowrap", marginRight: 10}}
                   onClick={() => navigator.clipboard.writeText(vividId).then()}
            >
                <span className="kt-font-brand">{vividId}</span>
                <i className="fa fa-clipboard" aria-hidden="true" style={{marginLeft: 5}}></i>
            </span>
        )}
        <div style={{ marginRight: 15, marginTop: 4 }}>
            <div>
                <a href={tmId?`https://bowman.plessinc.com/event/${tmId}`:'#'} target={tmId?`_blank`:''} rel="noopener noreferrer"
                   style={{
                    cursor: tmId?'':'default',
                }}>
                    <div style={{
                        position: 'relative',
                        left: 5,
                        padding: 4,
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: 0.8,
                    }}>
                        <span style={{
                            color: tmId ? 'inherit' : 'gray'

                        }}>
                            Bow<br/>Man
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <div style={{ marginRight: 15, marginTop: 4 }}>
            {tmId ?
                <a href={`https://ticketmaster.com/event/${tmId}`}  target="_blank" rel="noopener noreferrer">
                    <TMLogo
                        style={{ height: 27, width: 32 }}
                    />
                </a>
                :
                <TMLogo
                    style={{ height: 27, width: 32, filter:"grayscale(100%"}}
                />
            }
        </div>
        <div style={{ marginRight: 15, marginTop: 4 }}>
          {sgId ?
              <a href={`https://seatgeek.com/e/events/${sgId}`}  target="_blank" rel="noopener noreferrer">
                <SGLogo
                  style={{ height: 27, width: 32 }}
                />
              </a>
            :
              <SGLogo
                style={{ height: 27, width: 32, filter:"grayscale(100%"}}
              />
          }
        </div>
        <div style={{ marginRight: 15 }}>
          {shId ? <a href={`https://www.stubhub.com/event/${shId}`}  target="_blank" rel="noopener noreferrer">
            <SHLogo
              style={{ height: 37, width: 40 }}
            />
          </a>
          : <SHLogo
          style={{ height: 37, width: 40, filter: "grayscale(100%)" }}
          />}
        </div>
      </div>
  );
};

export default MarketplacesIcons;
